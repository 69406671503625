import axios from 'axios'
import cookies from 'vue-cookies'
import router from '@/router'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + 'api'
axios.defaults.headers.common['Accept-Language'] = process.env.VUE_APP_LANGUAGE

axios.interceptors.request.use((config) => {
    const token = cookies.get('auth_token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

function handleApiError(error) {
    const status = error.response ? error.response.status : null

    if (status === 401) {
        // Redirect to log in
        cookies.remove('auth_token')
        router.push('/login')
        return Promise.resolve(error)
    }

    if (status === 404) {
        // Not found
        router.push('/404')
        return Promise.resolve(error)
    }

    if (status === 422) {
        return Promise.resolve(error)
    }

    if (status === 500) {
        // Server error
        router.push('/500')
        return Promise.resolve(error)
    }

    // Other errors
    return Promise.reject(error)
}

export default {
    // Auth
    login(data) {
        return axios.post('/auth/login', data).catch(handleApiError)
    },

    // Emails
    emailList(params) {
        const queryParams = new URLSearchParams();

        if (params.orderBy) {
            queryParams.set('orderBy', `${params.orderBy.column},${params.orderBy.direction}`);
        }
        if (params.perPage) {
            queryParams.set('perPage', params.perPage);
        } else {
            queryParams.set('perPage', 10);
        }
        if (params.search) {
            queryParams.set('search', params.search);
        }
        if (params.currentPage) {
            queryParams.set('currentPage', params.currentPage);
        }
        console.log(`${queryParams.toString()}`)
        return axios.get(`/emails?${queryParams.toString()}`).catch(handleApiError)
    },

    // Export
    exportEmail() {
        return axios({
            url: '/emails/export',
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Accept': 'text/csv'
            }
        }).catch(handleApiError)
    }
}
