import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: {
            name: ' Admin',
        },
    }),
    getters: {
        getUser: ({ user }) => user,
    },
    actions: {},
})
