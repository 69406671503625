<template>
    <div>
        <div class="container body">
            <div class="main_container">
<!--                <sidebar-panel />-->
                <top-panel />

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import TopPanel from '@/components/Layouts/TopPanel.vue'

export default {
    name: 'MasterView',
    components: { TopPanel },
    data() {
        return {
            drawer: null,
            theme: {
                defaultTheme: 'dark',
            },
        }
    },
}
</script>
