<template>
    <div>
        <div class="messages">
            <message-item />
        </div>
        <section class="container-central forms">
            <div class="form login">
                <div class="form-content">
                    <header>Login</header>
                    <div>
                        <div class="field input-field">
                            <input
                                type="email"
                                placeholder="Email"
                                class="input"
                                v-model="email"
                                @keyup.enter="loginSend"
                            />
                        </div>
                        <div class="field input-field">
                            <input
                                type="password"
                                placeholder="Password"
                                class="password"
                                v-model="password"
                                @keyup.enter="loginSend"
                            />
                            <i class="bx bx-hide eye-icon"></i>
                        </div>

                        <div class="field button-field">
                            <button @click="loginSend">Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '/src/store/messages'
import MessageItem from '@/components/Messages/MessageItem.vue'
import api from '@/api'

const HOME_URL = '/admin/email-list'

export default {
    name: 'LoginView',
    components: { MessageItem },
    data() {
        return {
            email: '',
            password: '',
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
    },
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        loginSend() {
            this.validateEmailField()
            this.validatePasswordField()

            if (this.getMessages.length < 1) {
                api.login({
                    email: this.email,
                    password: this.password,
                }).then((response) => {
                    if (response.status === 200) {
                        this.$cookies.set('auth_token', response.data.data.token, 60 * 60 * 24 * 7)
                        this.$router.push(HOME_URL)
                    }
                })
            }
        },
        validateEmailField() {
            if (!this.email) {
                this.addMessages({
                    title: 'Error',
                    type: 'error',
                    text: 'Email is required',
                    timer: 3,
                })
            }
        },
        validatePasswordField() {
            if (!this.password) {
                this.addMessages({
                    title: 'Error',
                    type: 'error',
                    text: 'Password is required',
                    timer: 3,
                })
            }
        },
    },
}
</script>
