<template>
    <div class="top_nav">
        <div class="nav_menu">
            <div class="nav toggle">
                <a id="menu_toggle"><i class="fa fa-bars"></i></a>
            </div>
            <nav class="nav navbar-nav">
                <ul class="navbar-right">
                    <li class="nav-item dropdown open" :class="{ show: isShowMenu }" style="padding-left: 15px">
                        <a
                            href="#"
                            class="user-profile dropdown-toggle"
                            aria-haspopup="true"
                            id="navbarDropdown"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            @click="isShowMenu = !isShowMenu"
                        >
                            <img src="/images/img.jpg" alt="" />{{ getUser.name }}
                        </a>
                        <div
                            class="dropdown-menu dropdown-usermenu pull-right"
                            :class="{ show: isShowMenu }"
                            aria-labelledby="navbarDropdown"
                        >
                            <a class="dropdown-item" href="#" @click="logout">
                                <font-awesome-icon :icon="['fas', 'right-from-bracket']" class="pull-right mr-3" />
                                Log Out
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '/src/store/user'

export default {
    name: 'TopPanel',
    data() {
        return {
            isShowMenu: false,
        }
    },
    computed: {
        ...mapState(useUserStore, ['getUser']),
    },
    methods: {
        logout() {
            this.$cookies.remove('auth_token')
            this.$router.push('/login')
        },
    },
}
</script>
