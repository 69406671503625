<template>
    <router-view></router-view>
</template>

<script>
/* eslint-disable */
export default {
    name: "App",
};
</script>

<style lang="scss">
@import "assets/styles";
</style>