<template>
  <master-view>
    <div class="right_col" role="main">
      <div class="col-md-12 col-sm-12">
        <div class="x_panel">
          <div class="x_title">
            <h2>List emails</h2>
            <div class="clearfix"></div>
          </div>
          <div class="x_content">
            <div class="item form-group">
              <div class="col-md-6 p-0">
                <div class="col-md-6 col-sm-6">
                  <input
                    type="text"
                    placeholder="Search"
                    class="form-control search-input"
                    v-model="searchObject.text"
                    @keydown.enter="searchToggle"
                  />
                </div>

                <!-- Search -->
                <button
                  v-if="!isSearchActive"
                  class="btn btn-primary search-button"
                  type="button"
                  @click="search"
                >
                  <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                </button>
                <button
                  v-if="isSearchActive"
                  class="btn btn-primary search-button"
                  type="button"
                  @click="clearSearch"
                >
                  <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>

                <!-- Export -->
                <button
                  class="btn btn-success export-button"
                  type="button"
                  @click="exportEmail"
                >
                  <font-awesome-icon :icon="['fas', 'file-export']" />
                </button>
              </div>
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <!-- id -->
                  <th class="table-header-title" @click="toggleSort('id')">
                    id
                    <font-awesome-icon
                      :icon="['fas', 'arrow-down-long']"
                      v-if="isCurrentSort('id', 'desc')"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'arrow-up-long']"
                      v-if="isCurrentSort('id', 'asc')"
                    />
                  </th>

                  <!-- email -->
                  <th class="table-header-title" @click="toggleSort('value')">
                    email
                    <font-awesome-icon
                      :icon="['fas', 'arrow-down-long']"
                      v-if="isCurrentSort('value', 'desc')"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'arrow-up-long']"
                      v-if="isCurrentSort('value', 'asc')"
                    />
                  </th>

                  <!-- Email action -->
                  <th class="table-header-title" @click="toggleSort('action')">
                    Email action
                    <font-awesome-icon
                      :icon="['fas', 'arrow-down-long']"
                      v-if="isCurrentSort('action', 'desc')"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'arrow-up-long']"
                      v-if="isCurrentSort('action', 'asc')"
                    />
                  </th>

                  <!-- Created_at -->
                  <th class="table-header-title" @click="toggleSort('created_at')">
                    Created at
                    <font-awesome-icon
                      :icon="['fas', 'arrow-down-long']"
                      v-if="isCurrentSort('created_at', 'desc')"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'arrow-up-long']"
                      v-if="isCurrentSort('created_at', 'asc')"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="(email, index) in getEmails.items" :key="email.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ email.id }}</td>
                <td>{{ email.value }}</td>
                <td>{{ email.action }}</td>
                <td>{{ dateFormat(email.created_at) }}</td>
                <td class="col-1"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <pagination :pagination="getPagination" @pageChanged="changePage" />
      </div>
    </div>
    <footer-panel />
  </master-view>
</template>

<script>
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from "@/views/MasterView.vue";
import Pagination from '@/components/Layouts/PaginationPanel.vue'
import { mapActions, mapState } from 'pinia'
import {useEmailsStore} from "@/store/emails";
import api from "@/api";
import moment from 'moment';

export default {
  name: "EmailList",
  components: {FooterPanel, MasterView, Pagination},
  data() {
    return {
      currentPage: null,
      orderBy: {
        column: 'id',
        direction: 'desc',
      },
      perPage: 10,
      searchObject: {
        text: '',
        active: false,
      },
    }
  },
  computed: {
    ...mapState(useEmailsStore, ['getEmails', 'getPagination']),
    isSearchActive() {
      return this.searchObject.active
    },
  },
  methods: {
    ...mapActions(useEmailsStore, ['loadEmails']),
    changePage(currentPage) {
      localStorage.setItem('emailListPage', currentPage)
      this.loadEmails({
        orderBy: this.orderBy,
        perPage: this.perPage,
        search: this.searchObject.text,
        currentPage: currentPage,
      })
    },
    clearSearch() {
      this.searchObject.text = ''
      this.searchObject.active = false
      localStorage.removeItem('emailSearch')
      this.loadEmails({
        orderBy: this.orderBy,
        perPage: this.perPage,
        search: this.searchObject.text,
        currentPage: this.currentPage,
      })
    },
    dateFormat(rawDate) {
      return moment(rawDate).format('YYYY-MM-DD HH:mm:ss');
    },
    exportEmail() {
      api.exportEmail().then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'emails.csv'); // Встановлення назви файлу для завантаження
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Видалення посилання з DOM після завантаження
        window.URL.revokeObjectURL(url); // Очищення створеного URL
      })
    },
    isCurrentSort(column, direction) {
      return column === this.orderBy.column && direction === this.orderBy.direction
    },
    search() {
      if (this.searchObject.text) {
        this.searchObject.active = true
        localStorage.setItem('emailSearch', JSON.stringify(this.searchObject))
        this.loadEmails({
          orderBy: this.orderBy,
          perPage: this.perPage,
          search: this.searchObject.text,
          currentPage: this.currentPage,
        })
      }
    },
    searchToggle() {
      if (this.searchObject.active && !this.searchObject.text) {
        this.clearSearch()
      } else {
        this.search()
      }
    },
    toggleSort(column) {
      if (column === this.orderBy.column) {
        if (this.orderBy.direction === 'desc') {
          this.orderBy.direction = 'asc'
        } else {
          this.orderBy.direction = 'desc'
        }
      } else {
        this.orderBy = {
          column: column,
          direction: 'desc',
        }
      }
      localStorage.setItem('emailListOrderBy', JSON.stringify(this.orderBy))
      this.loadEmails({
        orderBy: this.orderBy,
        perPage: this.perPage,
        search: this.searchObject.text,
        currentPage: this.currentPage,
      })
    },
  },
  created() {
    this.loadEmails({})
  },
}
</script>
