import { defineStore } from 'pinia'
import api from "@/api";

export const useEmailsStore = defineStore('emails', {
    state: () => ({
        emails: [],
    }),
    getters: {
        getEmails: ({emails}) => emails,
        getPagination: ({ emails }) => {
            const { ...paginationData } = emails
            return paginationData
        },
    },
    actions: {
        async loadEmails(params) {
            const response = await api.emailList(params)
            if (response.status === 200) {
                this.emails = response.data.data
            }
        },
    },
})
