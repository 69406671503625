// Views
import LoginView from '@/views/Auth/LoginView.vue'

// Router
import { createRouter, createWebHistory } from 'vue-router'
import EmailList from "@/views/Email/EmailList.vue";
import Error404 from "@/views/Errors/Error404.vue";
import Error500 from "@/views/Errors/Error500.vue";

const routes = [
  // Auth
  { path: '/login', name: 'Login', component: LoginView },

  // Emails
  { path: '/admin/email-list', name: 'EmailList', component: EmailList },

  // Errors
  {path: "/404", name: 'Error404', component: Error404, meta: {requireAuth: false,},},
  {path: "/500", name: 'Error500', component: Error500, meta: {requireAuth: false,},},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  to.meta.middleware.forEach((item) => {
    item({ to, from, next })
  })

  return next()
})

export default router
